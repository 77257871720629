<!--
 * @Description: 意见反馈
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-13 10:56:09
 * @LastEditTime: 2020-04-21 11:24:19
 -->
 <template>
  <div class="account-modal">
    <a-drawer
      title="意见反馈"
      width="850px"
      placement="right"
      @close="handleHideThisModal"
      :visible="feedbackDrawerVisible"
    >
      <a-card title="反馈信息" class="mb-20" size="small">
        <table class="n-table">
          <tbody>
            <tr>
              <th width="100">反馈时间：</th>
              <td>{{data.create_time}}</td>
            </tr>
            <tr>
              <th width="100">反馈内容：</th>
              <td>{{data.content}}</td>
            </tr>
            <tr>
              <th width="100">图片：</th>
              <td width="600">
                <div class="pic-box">
                <viewer :images="data.pic" >
                  <img v-for="src in data.pic" :src="src" :data-src="src" :key="src" />{{src}}
                </viewer>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </a-card>
      <a-card title="用户信息" class="mb-20" size="small">
        <table class="n-table">
          <tbody>
            <tr>
              <th width="100">用户账号:</th>
              <td>{{data.user__mobile}}</td>
              <th width="100" v-if="data.user__channel!==0">
                <span v-if="data.user__channel===1">抖音</span>
                <span v-if="data.user__channel===2">淘宝</span>昵称:
              </th>
              <td v-if="data.user__channel!==0">
                <span v-if="data.user__channel===1">{{data.user__douyin_nickname}}</span>
                <span v-if="data.user__channel===2">{{data.user__anchor_name}}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </a-card>
      <a-card title="设备信息" class="mb-20" size="small">
        <table class="n-table">
          <tbody>
            <tr>
              <th width="130">设备系统类型：</th>
              <td>
                <a-tag v-if="data.device_type===0">未知</a-tag>
                <a-tag color="#108ee9" v-if="data.device_type===1">iOS</a-tag>
                <a-tag color="#87d068" v-if="data.device_type===2">Android</a-tag>
              </td>
            </tr>
            <tr>
              <th width="130">设备系统版本：</th>
              <td>
                {{data.device_os_version}}
              </td>
            </tr>
            <tr>
              <th width="130">设备品牌：</th>
              <td>{{data.device_brand}}</td>
            </tr>
            <tr>
              <th width="130">设备型号：</th>
              <td>{{data.device_model}}</td>
            </tr>
          </tbody>
        </table>
      </a-card>
      <a-card title="折一版本信息" class="mb-20" size="small">
        <table class="n-table">
          <tbody>
            <tr>
              <th width="100">客户端：</th>
              <td>
                <a-tag v-if="data.client_type===0">未知</a-tag>
                <a-tag color="green" v-if="data.client_type===1">小程序</a-tag>
                <a-tag color="cyan" v-if="data.client_type===2">Android</a-tag>
                <a-tag color="purple" v-if="data.client_type===3">iOS</a-tag>
              </td>
            </tr>
            <tr>
              <th width="100">版本号：</th>
              <td>{{data.version}}</td>
            </tr>
          </tbody>
        </table>
      </a-card>
    </a-drawer>
  </div>
</template>
<script>
export default {
  name: "FeedbackDrawer",
  props: {
    data: {
      default: function() {
        return {};
      }
    }
  },
  data() {
    return {
      feedbackDrawerVisible: true,
      options: {
        url: "data-src"
      }
    };
  },
  methods: {
    // 隐藏modal
    handleHideThisModal(page = -1) {
      this.$emit("callback", page);
    },
    getSmallPic(url) {
      return url + "?x-oss-process=image/resize,m_fill,h_200,w_200";
    },
    inited(viewer) {
      this.$viewer = viewer;
    },
    show() {
      // this.$viewer.show();
    }
  }
};
</script>
<style lang="less" scoped>
.mr-20 {
  margin-right: 20px;
}
.mb-20 {
  margin-bottom: 20px;
}
.n-table {
  th,
  td {
    font-weight: normal;
    padding: 5px;
  }
  th {
    text-align: right;
  }
}
.pic-box {
  img {
    height: 150px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}
</style>
