<!--
 * @Author: 前胡
 * @LastEditors: 前胡
 * @Date: 2020-03-03 17:30:54
 * @LastEditTime: 2020-04-21 09:59:17
 * @Description: 意见反馈
 -->
<template>
  <div id="userManager">
    <a-table
      :scroll="{ x: 1200, y: tableScrollY }"
      v-auth="'operate_manage_feedback_list_page'"
      :rowKey="record => record.id"
      :columns="feedbackColumns"
      :dataSource="list"
      :pagination="pagination"
      :loading="listLoading"
      :locale="{ emptyText: '暂无用户反馈' }"
      @change="handleTableChange"
    >
      <template slot="name" slot-scope="record">
        <span v-html="setName(record.content)"></span>
      </template>
     <template slot="device" slot-scope="record">
        <a-tag v-if="record.client_type===0">未知</a-tag>
        <a-tag color="green" v-if="record.client_type===1">小程序</a-tag>
        <a-tag color="cyan" v-if="record.client_type===2">Android</a-tag>
        <a-tag color="purple" v-if="record.client_type===3">iOS</a-tag>
      </template>
      <template slot="operation" slot-scope="record">
        <a-button
          size="small"
          class="mr-10"
          @click="handleShowFeedbackDetailModal(record)"
        >查看</a-button>
      </template>
    </a-table>
    <!--查看账号-->
    <FeedbackDrawer
      :data="tempFeedbackData"
      v-if="feedbackDetailModalVisiable"
      @callback="hideFeedbackModal"
    />
  </div>
</template>
<script>
import {
  getFeedbackList,
} from "@/service/operate";
import FeedbackDrawer from "@/components/admin/operate/drawer-feedback";
import { checkPer } from "@/utils/index";

// 寄样申请列表
const feedbackColumns = [
  {
    title: "反馈内容",
    fixed: 'left',
    // dataIndex: "content",
    scopedSlots: { customRender:  "name"},
    align: "left",
    width: 520,
  },
   {
    title: "反馈时间",
    dataIndex: "create_time",
    align: "center",
    width: 120
  },
  {
    title: "折一客户端",
    align: "center",
    width: 80,
    scopedSlots: { customRender: "device" }
  },
 {
    title: "折一版本",
    dataIndex: "version",
    align: "center",
    width: 80,
  },
  {
    title: "操作",
    align: "center",
    fixed: 'right',
    width: 100,
    scopedSlots: { customRender: "operation" }
  }
];

export default {
  name: "FeedbackPage",
  components: {
    FeedbackDrawer
  },
  data() {
    return {
      tableScrollY: 600,
      form: this.$form.createForm(this),
      feedbackColumns,
      feedbackDetailModalVisiable: false,
      // 临时账号数据
      tempFeedbackData: {},
      listLoading: true,
      list: [],
      allRoles: [],
      searchParams: {
        search: "",
        is_active: undefined
      },
      pagination: {
        current: 1,
        pageSize: 10,
        pageSizeOptions: ["10", "20", "30", "40", "50"],
        showTotal: total =>
          `共 ${total} 条记录 第 ${this.pagination.current}/${Math.ceil(
            total / this.pagination.pageSize
          )} 页`,
        showQuickJumper: true,
        showSizeChanger: true,
        onShowSizeChange: (current, pageSize) =>
          (this.pagination.pageSize = pageSize)
      }
    };
  },
  created() {
    var windowHeight = document.documentElement.clientHeight || 1000;
    this.tableScrollY = windowHeight - 215;
  },
  mounted() {
    this.getFeedbackList();
  },
  methods: {
    setName(e) {
      //文字超出显示省略号
      return (
        '<span  title="' +
        e +
        '" style="display:inline-block;width: 100%;text-align: left;' +
        '        overflow : hidden;' +
        '        text-overflow: ellipsis;' +
        ' display: -webkit-box; ' +
        '-webkit-box-orient: vertical; ' +
        '-webkit-box-pack: left; ' +
        '-webkit-box-align: left; ' +
        '-webkit-line-clamp:2; ' +
        'overflow: hidden;">' +
        e +
        '</span>'
      );
    },
    // 获取意见反馈列表
    async getFeedbackList() {
      if (!checkPer("operate_manage_feedback_list_page")) {
        this.$message.error("无权限获取列表");
        return false;
      }
      const data = Object.assign(
        { page: this.pagination.current, page_size: this.pagination.pageSize },
        this.searchParams
      );
      this.listLoading = true;
      const { err, res } = await getFeedbackList(data);
      this.listLoading = false;
      if (!err) {
        if (res.success) {
          const pagination = { ...this.pagination };
          this.list = res.data.results;
          pagination.total = res.data.count;
          this.pagination = pagination;
        } else {
          this.$message.warning(res.message);
        }
      }
    },
    // 表格分页
    handleTableChange(pagination) {
      if (pagination) this.pagination.current = pagination.current;
      this.getFeedbackList();
    },
    // 显示查看
    handleShowFeedbackDetailModal(data) {
      this.tempFeedbackData = JSON.parse(JSON.stringify(data));
      this.feedbackDetailModalVisiable = true;
    },
    // 搜索查询按钮
    accountSearchCallback(res) {
      this.pagination.current = 1;
      this.searchParams = Object.assign({}, res);
      this.getFeedbackList();
    },
    // 隐藏用户信息drawer
    hideAccountModal(isNeedFresh = -1) {
      this.accountModalVisiable = false;
      if (isNeedFresh === 1) {
        this.pagination.current = 1;
        this.getFeedbackList();
      }
      if (isNeedFresh === 0) {
        this.getFeedbackList();
      }
    },
    // 隐藏用户详细信息drawer
    hideFeedbackModal() {
      this.feedbackDetailModalVisiable = false;
    },
  }
};
</script>
<style lang='less' scoped>
 #userManager {
   /deep/ .ant-table-body {
     //样式穿透
     overflow-x: auto !important;
   }
 }
.option-btns {
  margin-bottom: 20px;
  margin-top: 10px;
  .option-btn {
    margin-right: 10px;
  }
}
.mr-10 {
  margin-right: 10px;
}
</style>
